import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMediaQuery } from "react-responsive";
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { device } from "../../Content/Style/devices";
import { SITE_URLS } from "../../enums/Urls";
import { formatDateTime } from "../../utils/dateUtils";
import { IssueIdKey } from "../PatientView/PatientView";
import {
  IssueTagModel,
  PatientListItem,
  PatientListItemOwner,
  PatientListItemStatus,
  PatientListItemType,
  PatientListPatientModel,
  UserRoles,
} from "../../apiClient";
import { TableBodyRow, Td } from "@collabodoc/component-library";
import { CollapsableRowEntityInformation } from "./CollapsableRowEntityInformation";
import usePatientListFilters from "../../hooks/usePatientListFilters";
import { FILTER_KEYS } from "../../enums/enums";
import { StatusIcon } from "./StatusIcon";
import { GlobalContext } from "../../context/GlobalContext";

const CollapsableRow = ({
  patient,
  isLoading,
}: {
  patient: PatientListPatientModel;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobileL = useMediaQuery({ query: device.mobileL });
  const isTablet = useMediaQuery({ query: device.tablet });
  const isLaptopS = useMediaQuery({ query: device.laptopS });
  const isLaptopM = useMediaQuery({ query: device.laptopM });

  const { isFilterSelected } = usePatientListFilters();
  const showCompletedColumn = isFilterSelected(
    FILTER_KEYS.STATUS,
    PatientListItemStatus.Completed,
  );

  useEffect(() => {
    isLaptopS && setIsCollapsed(true);
  }, [isLaptopS]);

  const handleCollapse = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  const { showCareCenter } = useContext(GlobalContext);

  const handleRowClick = (item?: PatientListItem) => {
    let params = {};
    if (item && item.itemType === PatientListItemType.Issue) {
      const id = item.issueId!.toString();
      params = { [IssueIdKey]: id };
    }
    navigate(
      {
        pathname: generatePath(SITE_URLS.PATIENT_VIEW, {
          patientId: patient.id,
        }),
        search: createSearchParams(params).toString(),
      },
      {
        state: {
          reminderModalOpen:
            item && item.itemType === PatientListItemType.Reminder,
          isOtherRoleReminder:
            item &&
            item.itemType === PatientListItemType.Reminder &&
            item.itemOwner === PatientListItemOwner.Others,
          noteModalOpen: item && item.itemType === PatientListItemType.Note,
        },
      },
    );
  };

  const handleShowStatusIcons = (roles: UserRoles[]) => {
    return roles.length > 2 ? (
      <MyTooltip>
        <>
          <StatusIcon currentRole={roles[0]} />
          <StatusIcon extraRole={roles.length - 1} />
        </>
        <MyTooltipText>
          {roles.slice(1).map((role, index) => (
            <StatusIcon currentRole={role} key={index} />
          ))}
        </MyTooltipText>
      </MyTooltip>
    ) : roles.length === 0 ? (
      <StatusIcon currentRole={undefined} />
    ) : (
      roles.map((role, index) => <StatusIcon currentRole={role} key={index} />)
    );
  };

  const handleShowTags = (tags: IssueTagModel[]) => {
    if (!tags) return;
    return (
      <MyTooltip>
        {tags.length > 1 ? (
          <TagSection>
            <Tag>{tags[tags.length - 1].name}</Tag>
            <Tag>...</Tag>
          </TagSection>
        ) : (
          <TagSection>
            {tags.map((tag, index) => (
              <Tag key={index}>{tag.name}</Tag>
            ))}
          </TagSection>
        )}
        <MyTooltipText>
          <TagToolTipWrapper>
            {tags.map((tag, index) => (
              <Tag key={index}>{tag.name}</Tag>
            ))}
          </TagToolTipWrapper>
        </MyTooltipText>
      </MyTooltip>
    );
  };

  return (
    <TableBody $isLoading={isLoading}>
      <TableBodyRow onClick={() => handleRowClick(patient.listItems[0])}>
        <SpecialTd>{patient.name}</SpecialTd>
        <SpecialTd>{patient.personalNumber}</SpecialTd>
        <SpecialTd>{patient.residentDepartmentName}</SpecialTd>
        <SpecialTd renderCell={!isMobileL}>{patient.unitName}</SpecialTd>
        <SpecialTd renderCell={!isTablet && showCareCenter}>
          {patient.careCenterName}
        </SpecialTd>
        <SpecialTd renderCell={!isLaptopS}>
          <CollapsableRowEntityInformation
            count={patient.listItems.length}
            firstListItem={patient.listItems[0]}
            isCollapsed={isCollapsed}
            handleCollapse={handleCollapse}
          />
        </SpecialTd>
        <SpecialTd
          renderCell={!isLaptopM}
          showContent={isCollapsed || patient.listItems.length === 1}
        >
          {formatDateTime(patient.timeOfLastActivity)}
        </SpecialTd>
        <SpecialTd
          renderCell={!isLaptopS && showCompletedColumn}
          showContent={patient.listItems.length === 1}
        >
          {patient.listItems[0].status === PatientListItemStatus.Completed
            ? formatDateTime(patient.listItems[0].timeOfCompleted)
            : ""}
        </SpecialTd>
        <SpecialTd
          renderCell={!isTablet}
          showContent={isCollapsed || patient.listItems.length === 1}
        >
          {!patient.allRead && <FontAwesomeIcon icon={faEnvelope} />}
        </SpecialTd>
        <SpecialTd
          tooltip={true}
          renderCell={!isTablet}
          showContent={isCollapsed || patient.listItems.length === 1}
        >
          {handleShowTags(patient.tags)}
        </SpecialTd>
        <SpecialTd
          renderCell={!isTablet}
          showContent={isCollapsed || patient.listItems.length === 1}
          tooltip={true}
        >
          {handleShowStatusIcons(patient.itemRoles)}
        </SpecialTd>
      </TableBodyRow>
      {!isCollapsed &&
        patient.listItems.length > 1 &&
        patient.listItems.map((listItem, index) => (
          <TableBodyRow onClick={() => handleRowClick(listItem)} key={index}>
            <SpecialTd></SpecialTd>
            <SpecialTd></SpecialTd>
            <SpecialTd renderCell={!isMobileL}></SpecialTd>
            <SpecialTd renderCell={!isTablet}></SpecialTd>
            <SpecialTd renderCell={!isTablet && showCareCenter}></SpecialTd>
            <SpecialTd renderCell={!isLaptopS}>{listItem.itemName}</SpecialTd>
            <SpecialTd renderCell={!isLaptopM}>
              {formatDateTime(listItem.timeOfLastActivity)}
            </SpecialTd>
            <SpecialTd renderCell={!isLaptopS && showCompletedColumn}>
              {listItem.status === PatientListItemStatus.Completed
                ? formatDateTime(listItem.timeOfCompleted)
                : ""}
            </SpecialTd>
            <SpecialTd>
              {!listItem.isRead && <FontAwesomeIcon icon={faEnvelope} />}
            </SpecialTd>
            <SpecialTd tooltip={true}>
              {handleShowTags(listItem.tags)}
            </SpecialTd>
            <SpecialTd renderCell={!isTablet} tooltip={true}>
              {handleShowStatusIcons(listItem.currentRolesWithBall)}
            </SpecialTd>
          </TableBodyRow>
        ))}
    </TableBody>
  );
};

const SpecialTd = ({
  children,
  renderCell = true,
  showContent = true,
  tooltip = false,
}: PropsWithChildren<{
  renderCell?: boolean;
  showContent?: boolean;
  tooltip?: boolean;
}>) => {
  return renderCell ? (
    <StyledTd tooltip={tooltip}>{showContent && children}</StyledTd>
  ) : null;
};

export default CollapsableRow;

const loadingKeyFrame = keyframes`
  0% {
    opacity: 1;
  }
  100% {

    opacity: 0.5;
  }
`;

const TableBody = styled.tbody<{ $isLoading: boolean }>`
  border-top: 0 !important;
  animation: ${({ $isLoading }) => ($isLoading ? loadingKeyFrame : "none")} 0.5s
    ease-in-out infinite alternate;

  :nth-child(even) > tr {
    background-color: ${({ theme }) =>
      theme.table.row.odd.backgroundColor} !important;

    @media (pointer: fine) {
      :hover {
        background-color: ${({ theme }) =>
          theme.table.row.hover.backgroundColor} !important;
      }
    }

    @media (pointer: coarse) {
      :active {
        background-color: ${({ theme }) =>
          theme.table.row.hover.backgroundColor} !important;
      }
    }
  }

  :nth-child(odd) > tr {
    background-color: ${({ theme }) =>
      theme.table.row.even.backgroundColor} !important;

    @media (pointer: fine) {
      :hover {
        background-color: ${({ theme }) =>
          theme.table.row.hover.backgroundColor} !important;
      }
    }

    @media (pointer: coarse) {
      :active {
        background-color: ${({ theme }) =>
          theme.table.row.hover.backgroundColor} !important;
      }
    }
  }
`;

const StyledTd = styled(Td)`
  overflow: ${(props: { tooltip: boolean }) =>
    props.tooltip ? "visible" : "hidden"};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
  padding: 0.25rem 0.5rem !important;

  @media ${device.tablet} {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: clamp(12px, 2.4vw, 16px);
  }

  :last-child {
    text-align: right;
    white-space: nowrap;
  }

  vertical-align: middle !important;
`;

const MyTooltipText = styled.span`
  visibility: hidden;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};

  color: #fff;
  text-align: center;
  padding: 4px;
  border-radius: 8px 0 8px 8px;
  top: 108%;
  right: 15%;
`;

const MyTooltip = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: default;

  &:hover ${MyTooltipText} {
    visibility: visible;
  }
`;

const TagToolTipWrapper = styled.div`
  display: flex;
  gap: 2px;
`;

const TagSection = styled.div`
  display: flex;
  gap: 2px;
  justify-content: end;
`;

export const Tag = styled.div`
  min-width: 22px;
  background-color: darkseagreen;
  border: 1px solid #82a982;
  border-radius: 20px;
  text-align: center;
  color: white;
  font-size: small;
  font-weight: normal;
  padding: 1px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
